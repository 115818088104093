// paragraph


.paragraph-anchor {
	position: relative;
	top: -50px;
}

.paragraphs .paragraph .container {
  padding-top: 50px;
}
.paragraphs {
	margin-bottom: 50px;
}
@media (max-width: @screen-tablet) {
  .paragraphs .paragraph .container {
	  padding-top: 50px;
  }
  .paragraphs {
  	margin-bottom: 50px;
  }
}
@media (max-width: @screen-phone) {
  .paragraphs .paragraph .container {
    padding-top: 50px;
    border-top: 1px solid @gray-lighter;
  }
  .paragraphs {
  	margin-bottom: 50px;
  }
}

.paragraph-img {

	margin-bottom: 50px;

	img {
		border-radius: @border-radius-large;
	}

	.img-default {
		// float: right;
		display: block;
		// width: 400px; // 540px;
		height: auto;
		margin: 0 auto;
	}
}

// mobile first
.paragraph-img {

	.img-default {
		width: 260px;
	}
	.img-align {
		float: none;
	}
}

// @media (max-width: 1024px) {
@media (min-width: (@screen-phone + 1)) {

	.paragraph-img {

		.img-default {
			width: 378px;
		}
	}
}

// @media (max-width: @screen-lg-desktop) {
@media (min-width: 1025px) {

	.paragraph-img {

		.img-default {
			width: 400px;
		}
	}
}

// @media (min-width: @screen-lg-min) {
@media (min-width: @screen-lg-min) {

	.paragraph:nth-child(2n) {
		.paragraph-img {
    	left: percentage((7 / @grid-columns));

			.img-align {
				float: right;
				margin-top: 46px;
			}
		}

		.paragraph-copy {
    	right: percentage((5 / @grid-columns));
		}
	}
	.paragraph:nth-child(2n+1) {
		.img-align {
			float: left;
			margin-top: 46px;
		}
	}

}

