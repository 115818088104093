// custom.less

// ***************
// navbar

// facebook icon
.navbar-nav>li:last-child>a {
    padding-top: 10px; // @navbar-padding-vertical;
    padding-bottom: 8px; // @navbar-padding-vertical;
}

.page_1 .navbar-nav>li:last-child,
.page_2 .navbar-nav>li:last-child,
.page_thk .navbar-nav>li:last-child {
    display: none;
}


// ***************
// jumbotron

.page_1 .jumbotron {
    margin-bottom: 10px;
}

.jumbotron {
    padding: 0;
}

.jumbotron-register-info {
    margin-top: 10px;
}

.link-container {
    margin-top: 30px;

    a {
        padding: 8px 0;
    }
}

.jumbotron .container {
    position: relative;
}

.jumbotron-image {
    margin: 10px 0 0 0;
    background-size: cover;

    .image-logo-kvn {
        width: 100%;
        max-width: 408px;
    }
}

.page_2 {
    .jumbotron-image {
        height: 170px;
        background-image: url('/images/plexus-doc-mobile.jpg');
    }
}

.page_3 {
    .jumbotron-image {
        img {
            width: 100%;
        }
    }
}

.jumbotron h1 {
    font-weight: 200;
}

.jumbotron-content {
    padding: 12px 0 0;

    h1 {
        font-size: (@font-size-base * 1.15);
    }

    p {
        font-size: (@font-size-base * 0.8);
        margin-bottom: 12px;
    }

    .jumbotron-link {
        a {
            color: @link-color;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 0.9em;
            opacity: 0.8;
            border-bottom: 1px solid @link-color;
        }

        a:hover,
        a:active {
            text-decoration: none;
            opacity: 1;
        }
    }
}

.jumbotron-link,
.jumbotron-ticker {
    margin-bottom: 15px;
}

.jumbotron-ticker {
    width: 100%;

    a {
        color: @brand-app;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        letter-spacing: 0.03rem;
        border-bottom: 1px solid @brand-app;
    }

    a:hover,
    a:active {
        text-decoration: none;
    }
}

.button-box {
    display: flex;

    p {
        margin-right: 20px;
    }
}

@media screen and (min-width: @screen-xs-min) {

    .jumbotron-image {
        // .center-block();
        margin-top: 27px;
    }

    .page_2 {
        .jumbotron-image {
            background-image: url('/images/plexus-doc-518886382.jpg');
            height: 272px;
        }
    }

    .page_3 {
        .jumbotron-image {
            img {
                width: 100%;
                height: auto;
            }

        }
    }

}

@media screen and (min-width: @screen-sm-min) {
    .jumbotron .container {
        .clearfix();
    }

    .jumbotron-content {
        float: right;
        width: 55%;
        padding-top: 20px;

        h1 {
            font-size: (@font-size-base * 1.35);
            margin-bottom: 16px;
        }

        p {
            font-size: (@font-size-base * 0.9);
        }
    }

    .jumbotron-image {
        float: left;
        width: 40%;
        // height: 216px;
        margin-top: 27px;
    }
}

@media screen and (min-width: @screen-md-min) {
    .jumbotron-content {
        h1 {
            font-size: (@font-size-base * 1.4);
        }

        p {
            font-size: (@font-size-base * 1);
        }
    }

}

@media screen and (min-width: @screen-lg-min) {
    .jumbotron-content {
        width: 50%;
    }
}


// ********************
// stripe_hero

.stripe-hero {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 200px;
}

@media screen and (min-width: @screen-sm-min) {
    .stripe-hero {
        height: 300px;
        background-size: auto 300px;
    }
}

@media screen and (min-width: @screen-md-min) {
    .stripe-hero {
        height: 350px;
        background-size: auto 350px;
    }
}

// ********************
// stripe_hero

.stripe-spots {
    margin: 5px 0 50px;
    padding: 40px 0 0;

    .content {
        text-align: center;
    }
}

// ********************
// stripe_access

.stripe-access {
    margin-top: 5px;
    margin-bottom: 0; // 50px;
    padding: 40px 0 20px;
    background-color: #f6f6f6;

    .content {
        text-align: center;
    }

    .button {
        display: table;
        margin: 0 auto;
    }

    .subline {
        margin-top: 10px;
        text-align: center;
        font-weight: 200;
    }
}

// ********************
// stripe_login

.stripe-spot2 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 45px 0;
    // background-color: lighten(@brand-app, 40%);
    background-color: #f6f6f6;


    .container {
        text-align: center;

        .content {
            // line-height: 50px;
            font-size: (@font-size-base * 1);
            font-weight: 200;

            p {
                margin-bottom: 7px;
            }
        }

        // .spot-label-xs {
        // 	display: inline;
        // }
        // .spot-label-sm {
        // 	display: none;
        // }
    }
}


// @media screen and (min-width: @screen-sm-min) {
// 	.spots-bar .container {
// 		.spot-label-xs {
// 			display: none;
// 		}
// 		.spot-label-sm {
// 			display: inline;
// 		}
// 	}
// }



// ********************
// spots


.spots {
    margin-top: 5px;

    .spot-content {
        position: relative;
        z-index: 11;
    }

    .spot-icon {
        // position: absolute;
        z-index: 10
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots {
        padding-top: 30px;
        margin-top: 5px;
        margin-bottom: 50px;

    }
}

// ***************
// spots-info
.spots {
    .spot-info {
        p{
            background: #A6A6A6;
            color: #fff;
            margin-top: 10px;
            padding: 10px 30px;
            text-align: center;
        }
    }
}


// ***************
// spots-bar

.spots-bar {
    height: 50px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    // background-color: lighten(@brand-app, 40%);
    background-color: #e6e6e6;

    .container {
        text-align: right;

        .spot-label {
            line-height: 50px;
            font-size: (@font-size-base * 1);
            font-weight: 200;
        }

        .spot-label-xs {
            display: inline;
        }

        .spot-label-sm {
            display: none;
        }
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots-bar .container {
        .spot-label-xs {
            display: none;
        }

        .spot-label-sm {
            display: inline;
        }
    }
}

// ***************
// spots-1

.spots-1 {
    padding-top: 0;

    div.row {
        padding-top: 0;


        .spot-content {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 20px 20px 6px 20px;
            background-color: #f0f0f0;

            .spot-icon {
                position: relative;
                top: 0;
                right: 0;
                text-align: center;
                margin-bottom: 10px;
            }


        }
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots-1 {
        padding-top: 0;

        div.row {
            padding-top: 0;

            .spot-content {
                padding: 30px 30px 16px 30px;
            }
        }
    }
}

.spots-1 {
    .spot-stethoscope {
        top: 20px;
        right: 45px;
    }

    .spot-folder-heart {
        top: 20px;
        right: 45px;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots-1 {
        .spot-stethoscope {
            top: 20px;
            right: 165px;
        }

        .spot-folder-heart {
            top: 20px;
            right: 165px;
        }
    }
}

// ***************
// spots-bg - colors

.spots-bg {
    background-color: #f6f6f6; // #E6F2EA; // #f6f6f6; 
}

.page_1 {
    .spots .spot-icon i {
        color: #ccc;
    }

    .spots-bg {
        background-color: #fff; // #E6F2EA; // #f6f6f6; 
    }
}

.page_2 {
    .spots .spot-icon i {
        color: #e6e6e6; // #CCEBD7;
    }

    .spots-bg {
        background-color: #f6f6f6; // #E6F2EA; // #f6f6f6; 
    }
}

.page_3 {
    .spots .spot-icon i {
        color: #CCEBD7;
    }

    .spots-bg {
        background-color: #E6F2EA; // #E6F2EA; // #f6f6f6; 
    }
}

// ***************
// spots-topic

#spots-topic {
    // position: relative;
    top: -50px;
}

.spots.spots-topic {
    margin-top: -30px;
    margin-bottom: 0px;
    padding-top: 20px;

    color: #fff;

    &.spots-bg {
        background-color: #77BA8F; // #4c9967; // #F7E1EE;
    }

    .row {
        padding-top: 0px;
        padding-bottom: 15px;

        .spot {
            padding-top: 30px;
        }
    }

    .spot-icon i {
        font-size: 70px;
        opacity: 0.3;
    }

    .spot-icon.spot-new i {
        color: #E1F296;
        opacity: 0.4;
    }

    .spot-content {
        height: 60px;
        margin-left: 20px;
    }

    h2 {
        font-size: (@font-size-h2 * 0.6); // 0.7
        font-weight: 200;
    }

    p {
        font-size: (@font-size-base * 0.6); // 0.7
    }

    a {
        color: #fff;
        opacity: 0.8;
    }

    a:hover,
    a:active,
    a:focus,
    a:link,
    a:visited {
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid #fff;
    }

    a:hover {
        opacity: 1;
    }
}

.page_2 .spots.spots-topic {

    // DOC
    &.spots-bg {
        background-color: #666666; // #77BA8F;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots.spots-topic {
        .spot-icon i {
            font-size: 80px;
        }

        .spot-content {
            height: 80px;
        }
    }
}

@media screen and (min-width: @screen-md-min) {
    .spots.spots-topic {
        h2 {
            font-size: (@font-size-h2 * 0.66); // 0.8
        }

        p {
            font-size: (@font-size-base * 0.66); // 0.8
        }
    }
}


.spots.spots-topic {
    .spot-star {
        top: 0px;
        left: 0px;
    }

    .spot-chat {
        top: 10px;
        left: 10px;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots.spots-topic {
        .spot-star {
            top: 0px;
            left: 10px;
        }

        .spot-chat {
            top: 10px;
            left: 10px;
        }
    }
}


// ***************
// spots-usps

.page_1 .spots.spots-usps.spots-bg {
    background-color: #666666;
}

.page_2 .spots.spots-usps.spots-bg {
    background-color: #666666; // #9873D1;
}

.page_3 .spots.spots-usps.spots-bg {
    background-color: #77BA8F; // #ca75a8;
}


.spots.spots-usps {
    margin-top: 5px;
    margin-bottom: 0;
    padding-top: 10px;
    color: #fff;

    .row {
        padding-top: 0px;
        padding-bottom: 15px;

        .spot {
            padding-top: 30px;
        }
    }

    .spot-icon i {
        font-size: 50px;
        color: #fff;
        opacity: 0.2;
    }

    .spot-content {
        margin-left: 20px;
    }

    h2 {
        font-size: (@font-size-h2 * 0.7);
        font-weight: 200;
    }

    p {
        font-size: (@font-size-base * 0.7);
    }

    a {
        color: #fff;
        opacity: 0.8;
    }

    a:hover,
    a:active,
    a:focus,
    a:link,
    a:visited {
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid #fff;
    }

    a:hover {
        opacity: 1;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots {

        &.spots-usps {
            margin-top: 5px;
            margin-bottom: 0px;

            .spot-icon i {
                font-size: 60px;
            }

            .spot-content {
                margin-left: 40px;
            }
        }
    }

    .stripe-spot2 {

        padding: 15px 0;
    }
}

@media screen and (min-width: @screen-md-min) {
    .spots.spots-usps {
        h2 {
            font-size: (@font-size-h2 * 0.8);
        }

        p {
            font-size: (@font-size-base * 0.8);
        }
    }
}

.spots.spots-usps {
    .spot-tick {
        top: 0px;
        left: 10px;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .spots.spots-usps {
        .spot-tick {
            top: 0px;
            left: 10px;
        }
    }
}


// ********************
// spots-custom

.page_3 .spots-custom {
    margin-bottom: 0;
}

// ********************
// button

.btn {
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
}

// ***************
// form-access

form.form-access .form-group-target {
    margin: 30px 0;
}

// ********************
// passage anchor

#top-topic,
#doc-top-topic,
#mfa-top-topic,
#road0 {
    top: -50px;
}

// #evidenzbasiert,
// #praxisrelevant {
// 	top: -50px;
// }
#arzneimittelwirksamkeit,
#aktuelle-medizinthemen {
    top: -50px;
}

@media screen and (max-width: (@screen-xs-min - 1)) {

    #evidenzbasiert,
    #anwendungsorientiertes-wissen {
        top: -50px;
    }
}

#stripe-spot,
#stripe-start,
#online-fortbildungen-fuer-das-gesamte-praxisteam,
#doc-zugang-zu-plexus-fortbildungen,
#mfa-zugang-zu-plexus-fortbildungen {
    position: relative;
    top: -50px;
}

// @media screen and (min-width: @screen-xs-min) {
// 	#stripe-start {
// 		top: -100px;	
// 	}
// }


// ********************
// passage bg

.passage {
    margin-bottom: 50px;
}

.passage.message {
    // border: solid thin;
    border-radius: 4px;
    margin-bottom: 0;
    background-color: #f0f0f0;

    p {
        padding: 30px 30px 14px 30px;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .passage.message {
        margin-bottom: 50px;
    }
}


.passage-topic {
    margin-top: 5px;
    margin-bottom: 0;
    padding-top: 50px;
    padding-bottom: 50px;
}

.psg-inverse {
    color: #fff;

    a {
        color: #fff;
        opacity: 0.75;
        text-decoration: underline;
    }

    a:hover,
    a:focus {
        color: #fff;
        opacity: 1;
        text-decoration: underline;
    }
}

// ***************
// testimon

// .testimon {
// 	.clearfix();
// }
// .testimon-text {

// }
// .testimon-text-wrapper {
// 	position: relative;
// 	margin: 50px 0;
// 	border-radius: 10px;
// 	background-color: #fff;
// }
// .testimon-image {

// 	.testimon-image-wrapper {
// 		.center-block();
// 		margin-top: 50px;
// 		width: 200px;
// 		height: 200px;
// 		border-radius: 100px;
// 		overflow: hidden;
// 	}

// 	img {
// 		width: 100%;
// 		height: auto;
// 	}

// 	.testimon-subline {
// 		display: table;
// 		margin: 10px auto 30px auto;
// 	}
// }

// @media screen and (min-width: @screen-sm-min) {
// 	.testimon-text-wrapper {
// 		padding: 0; // 20px;
// 		margin-right: 50px;
// 	}
// }

// ***************
// testimon carousel

.carousel {
    margin: -50px 0;
}

.carousel-inner {

    // background-color: #eeeeee;
    .container {
        padding: 30px 10px;

        .item-inner {
            height: 200px;
            background-color: #e6e6e6;
            padding: 20px 20px;

            blockquote {
                padding: 5px 10px;

                p {
                    font-size: (@font-size-base * 0.8);

                    &:before {
                        content: '';
                    }
                }
            }


            .carousel-item-cell-quote {
                height: 106px;
                vertical-align: middle;
            }

            .carousel-reference {
                padding: 0 10px;
                font-size: (@font-size-base * 0.6);
            }
        }
    }
}

.carousel-indicators {
    margin-bottom: 25px;
}

// @media screen and (min-width: @screen-xs-min) {
// 	.carousel-inner {
// 		.container {
// 			.item-inner {
// 				padding: 15px 25px;
// 			}
// 		}
// 	}
// }
@media screen and (min-width: @screen-sm-min) {
    .carousel {
        margin: 0;
    }

    .carousel-inner {
        .container {
            padding: 30px;

            .item-inner {
                height: 240px;
                padding: 30px;

                blockquote {
                    padding: 14px 28px;

                    p {
                        font-size: (@font-size-base * 1.1);
                    }
                }

                .carousel-item-cell-quote {
                    height: 127px;
                }

                .carousel-reference {
                    padding: 0 28px;
                    font-size: (@font-size-base * 0.7);
                }
            }
        }
    }

    .carousel-indicators {
        margin-bottom: 14px;
    }
}

.carousel-control,
.carousel-control.right,
.carousel-control.left {
    background: transparent;

    .glyphicons {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;
    }
}

@media screen and (min-width: @screen-sm-min) {

    .carousel-control,
    .carousel-control.right,
    .carousel-control.left {
        background: transparent;

        .glyphicons {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -10px;
        }
    }
}

// ********************
// footer

.footer {
    .container {
        overflow: hidden;
    }
}

// footer newsletter
.footer .footer-logo,
.footer .footer-newsletter {
    margin-bottom: 50px;

    .btn {
        color: #606060;
    }
}

// footer social
.footer .footer-social {
    margin-top: 0;

    .social {
        color: #aaa;
        font-size: 50px;
        line-height: 1em;
    }
}

.page_1 .footer-social,
.page_2 .footer-social {
    display: none;
}

// ***************
// modal

@media screen and (max-width: @screen-xs-max) {

    // < 768px
    .modal {
        .m-window {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-left: 0;
            margin-bottom: 0;
            border: 0 none;

            .m-content {
                background-color: #fff;

                .container-form {
                    width: auto;
                }
            }
        }
    }

    .modal-msg {
        border: 0 none;
    }

    body.modal-bob-open .body-content {
        display: none;
    }
}

// *****
// ios

.ios {
    .modal {
        position: absolute;

        .m-background {
            display: none;
            position: absolute;
        }

        .m-window {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-left: 0;
            margin-bottom: 0;
            border: 0 none;
            border-radius: 0;

            .m-content {
                background-color: #fff;
                // width: 540px;
                // margin: 0 auto;
            }
        }
    }

    .modal-msg {
        top: 0;
        left: 0;
        width: 100%;
        margin-left: 0;
        border-radius: 0;
        border: 0 none;

        .msg-content {
            border-radius: 0;
        }
    }

    body.modal-bob-open .body-content {
        display: none;
    }
}

@media screen and (min-width: @screen-sm-min) {

    // >= 768px
    .ios {
        .modal {
            .m-window {
                .m-content {
                    width: 600px;
                    margin: 0 auto;
                }
            }
        }
    }
}

// ***************