.externalform {
    min-width: 300px;
    width: 100%;
    margin: 10px auto;
    padding: 30px;

    .btn-primary {
        color: #fff;
        background-color: @brand-app;
        border-color: @brand-app;

        &:hover,
        &:focus,
        &:active,
        &:active {
            color: #fff;
            background-color: @brand-app;
            border-color: @brand-app;
            /*set the color you want here*/
        }
    }
}

.success-content {
    padding: 70px 30px 100px 30px;
  }

@media screen and (min-width: @screen-sm-min) {
    .container-form-external {
        width: 540px;
        margin: 70px auto;
    }
.externalform {
    padding: 0px;
}

.success-content {

    display: block;
    padding: 70px 30px 0px 30px;
    min-height: 300px;
  }
}


  div#bottom {
    top: 0px;
  }


// footer bottomize fix

.page_4 {

    .body-content {
        // position: relative;
        height: auto;
    }
}
